
  @import url('https://fonts.googleapis.com/css2?family=Righteous&family=Roboto+Condensed&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #336699;
opacity: 0.8;

font-family: 'Roboto Condensed', sans-serif;

}

/* .App-header{
	display:flex;
	flex-direction:row;
	align-items: flex-start;
	justify-content: flex-start;
	
} */

.App-header {
	background-color: #fdd532;
	 display: flex; 
	height: 7vh;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	font-size: calc(10px + 2vmin);
	color: white;
	background-image: linear-gradient(45deg, #fdd532 50%, #ec2 50%);
  background-size: 5px 5px;
  }
  
  .App-link {
	color: #61dafb;
  }

.App-header .heading{
	font-family: 'Righteous', cursive;
	font-size: 3.5vh;
	color:#303035;
}

.subtitle{
	
	font-size: 2vh;
	color:#336699;
}

.hidden{
	display:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#cgptResp{
	width:70%;
	font-size: .65em;
}

.dialog{
	font-size: .5em;;
}

.logo-wrapper{
	margin-left:1.0vw;
	margin-right:2.0vw;
}

.titles{
	margin-right:3vw;
}

.logo{
	/* position:absolute; */
	top: 2vh;
	left:2vh;
	width: 10vw;
	max-width: 90px;
}

.nav{
	display:flex;
	align-items: center;
	
	align-self: center;
	color:#303035;
	font-size:2.2vh;
	width:40%;
	height:90%;
}

.menu-item{
	display:flex;
	align-items: center;
	justify-content: center;
	flex:1;
	height:100%;
}
.menu-item:hover{
	background-color:#264f78;
	color:#fff;
	cursor:pointer
}
.menu-divider{
	flex:0.2;
}

.popup-box{
	/* display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center; */
	position:fixed;
	top:20%;
	left:10%;
	width:70%;
	height:auto;
	background-color: #fffff5;
	z-index: 999;
	padding:3vw;
	font-size:1.75vh;
}

.whatis-text div{
	margin-bottom:15px;
}
.close-btn{
	position:absolute;
	top:.25vw;
	right:2vw;
	width:2.25vw !important;
	 height:2.25vw !important;
	/* background-color: #336699; */
	color:#264f78;
	/* font-size:2.5vw; */
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
}

.close-btn .icon{
	font-size:1.75vw;
}

.qInput{
	background-color: #f5f5f5;
	border-radius: 7px;
}

.dialog-wrapper{
	display:flex;
	flex-direction:row;
	width:100%;
	height:93vh;
	margin-top:0vh;
	box-sizing: border-box;
	background-image: linear-gradient(45deg, #264f78 50%, #336699 50%);
background-size: 5px 5px;
}

.main-frame{
	display:flex;
	flex:3;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	
	height:100%;
	box-sizing: border-box;
	}
	.dialog-frame{
		height:100%;
		width: 100%;
		overflow:hidden;
	}

	.patient-frame{
		
		width:calc( 100% - 250px );
		margin-left:125px;
		position: relative;
		
		padding:0;
		margin-bottom:0px !important;
		border-left: solid 5px #001530;
		border-right: solid 5px #001530;
		border-bottom: solid 5px #001530;
		box-sizing: border-box;
	}


	.patient-frame img{
		display:block;
		max-width: 100%;
		max-height: 100%;
		width:100%;
		height:auto;
		object-fit: contain;
		box-sizing: border-box;
	}

	.patient-statements{
		position:absolute;
		top: 0;	
		left:0;
		width:100%;
		min-height:12%;
		background-color: #00000040;
		font-size: 1vw;
		color:#fff;
		overflow:hidden;
		max-width: 100%;
		padding:12px;
text-align:left;
box-sizing: border-box;
		
	}

	.dashboard{
		display:flex;
		flex:1;
		flex-direction:column;
		align-items:center;
		justify-content:top;
		background-color: #ffffdd;
		
	}

	.dashboard .heading{
		font-weight:bold;
		color:#336699;
		margin-bottom:5px;
	}

	.archive{
		margin:.5vw;
		width:100%;
	}
	.archive-results .MuiInputBase-root {
		text-align: left;
		font-size: .8vw !important;
	width:100% !important;
	
	} 
	.archive-results .MuiFormControl-root{
		text-align: left;
		font-size: .7 !important;
	width:90% !important;
	background-color: #ffffe8;
	
	}

	.archive-list{
		width:100%;
		
	}

	.talk-frame{
		display:flex;
		flex-direction: row;
		align-items:center;
		justify-content:center;
		width:90%;
		margin-top:25px;
		margin-bottom:10px;
	}

	.q-input{
		width:90%;
		margin-right:25px !important;
		background-color: #f5f5f5;
		border-radius:5px;
		
	}

	.q-input label{
		background-color: #f5f5f5;
		padding:6px;
		border-radius:12px;
		font-size: .9vw;
		margin-top:-5px !important;
	}

	.q-input .MuiInputBase-root {
		font-size:.95vw !important;
		flex:5;
	}

	

	.mic{
		flex:1;
	}
.mic-wrapper{
	position:relative;
	width:55px;
	height:55px;
}	

.submit-btn{
	display:flex;
	width:80%;
	justify-content: end;
	margin-bottom:25px;
	
}

.submit-btn button{
	margin-right:15px;
	margin-left:15px;
}
.btn {
	border: none;
  padding: 0;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  font-size: 100%;
  color: #fff;
  padding: 0;
  margin: 0;
  background-color: #f5f5f5;
	position: relative;
	z-index: 999;
	display:flex;
	align-items:center;
	justify-content:center;
  cursor:pointer;
}

.btn .MuiSvgIcon-root{
	font-size:2.5em;
	color:#404040
}

.listening .btn {
	background-color:#bdf388;
}
.listening .btn .MuiSvgIcon-root{
	color:#990000;
}
.listening .pulse-ring {
	display:block;
}
.pulse-ring {
	display:none;
	
  content: '';
  width: 100%;
  height: 100%;
	background: #990000;
  border: 5px solid #990000;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -5px;
  animation: pulsate infinite 1.5s;
}

.pulse-ring.delay{
	 animation-delay: 1s;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.3, 1.3);
    opacity: 0;
  }
}

.patient-stats{
	width:100%;
	margin-top:2vh;
}

.doctor-stats{
	width:100%;
	margin-top:2vh;
}

.stats-wrapper{
	display:flex;
	flex-direction:row;
	flex-wrap: wrap;
	width:100%;
	/* height:100%; */
	justify-content: left;
	align-items:top;
}


.indicator{
	flex:1;
	padding-left:.5vw;
	padding-right:.5vw;
}

.indicator.dialog-score{
	
	padding-left:20%;
	padding-right:20%;
	margin-top:10px;
	margin-bottom:15px;
}
.MuiLinearProgress-bar{
	background-color: #52df36 !important;
}
.low-level .MuiLinearProgress-bar{
	background-color: #aa0000 !important;
}
.mid-level .MuiLinearProgress-bar{
	background-color: #f1bd12 !important;
}
.high-level .MuiLinearProgress-bar{
	background-color: #52df36 !important;
}

.ind-label{
	font-size: .8vw;
}
.ind-value{
	font-size: .9vw;
}

.metric{
	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: center;
	font-size: .85vw;
	height:0.85vw;
	padding-left: .35vw;
	padding-right: .35vw;
	margin-right:.35vw;
	margin-bottom:15px;
}

.m-label{
	display:flex;
	justify-content: center;
	/* flex:1; */
	text-align:left;
	height:3vh;
}
.m-icon{
	/* position:relative; */
	display:flex;
	justify-content: top;
	/* flex:.25; */
	color:#336633;
	padding-left: .25vw;
	height:3vh;
}
.m-icon svg{
	display:flex;
	/* height: 1.35vw; */
	height: 85%;
}
.m-icon .m-value{
	display:flex;
	/* position:absolute;
	right:-.8vw;
	bottom:.2vw; */
	font-size:.65vw;
	color:#336633;
	font-weight:bold;
	width:.65vw;
}

.bkdrop-text{
	position:absolute;
	font-size: 1.1vw;
	color:#fff;
	text-align:center;
	margin-top:8vh;
	font-weight: normal;
}

/* media query for mobile devices*/

 @media screen and (max-width: 1023px) {
	 
	.m-icon svg{
		
		height: 65%;
	}
	.m-icon .m-value{
		
		font-size:1.5vh;
		
	}

	.popup-box{
		
		font-size:2.5vh;
	}

	.close-btn{
		 height:auto !important;
		}
	
 }